$red_color: #c00329;
$light-red_color: #ea4865;
$dark-blue_color: #102e6d;
$bg-blue_color: #002a6f;
$bg-dark-blue_color: #042556;
$light-blue_color: #2b7eb3;
$middle-blue_color: #132654;
$black_color: #010101;
$grey_color: #d4d3d3;
$white_color: #FAFAFA;
$yellow_color: #c1b621;
$green_color: #40893a;
