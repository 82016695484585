body.dashboard-page {
  .dashboard-grid-container {
    display: grid;
    grid-template-areas: 'header'
    'content'
    'footer';

    grid-template-rows: 4.5rem auto 5rem;

    .grid-item-header {
      grid-area: header;
    }
    .grid-item-content {
      grid-area: content;
    }

    .grid-item-footer{
      grid-area: footer;
    }
  }
}