.document_upload{
  padding: 10px;
  border: 0.125em dashed $light-blue_color;
  border-radius: 6px;
  background: #f0f8ff;
  position: relative;
  .text-false{
    display: flex;
    .fa{
      font-size: 42px;
      color: $light-blue_color;
      margin-right: 15px;
    }
    >div{
      >span{
        display: block;
        &:nth-child(2){
          font-size: 12px;
        }
      }
    }
  }
  input[type="file"]{
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    border: 0;
  }
  .progress{
    height: 100%;
    .progress-bar{
      height: 100%;
      width: 0;
      @include transition(all .9s ease-out);
      background: $light-red_color;
    }
  }
  .drag-drop{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    text-align: center;
    padding: 5px;
    background-color: rgba(255,255,255,.8);
    &.over{
      i{
        color: #19a974;
      }
    }
  }
  .container-document{
    .file{
      position: relative;
      padding: 10px;
      border: 1px solid #e4e4e4;
      border-radius: 6px;
      >span{
        display: block;
        &.size{
          font-size: 12px;
          margin-top: 3px;
        }
      }
      .check, .close{
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 25px;
        transition: all 0.3s ease;
        cursor: pointer;
      }
      .check {
        color: #94bfea;
        &:hover {
          opacity: 0;
        }
      }
      .close {
        color: #ea94ab;
        opacity: 0;
        &:hover {
          opacity: 1;
        }
      }

    }
  }
}