.navbar {
  height: 4.5rem !important;

  &-brand {
    font-size: 2rem;
  }

  &-avatar > i{
    font-size: 3rem;
  }

  a {
    &, &:hover {
      color: $white_color;
      text-decoration: none;
    }
  }

  #menu-toggle {
    padding-top: .4rem;
    margin-right: 1rem;
  }

  .dropdown {
    a.dropdown-item {
      color: $black_color;
    }
  }
}