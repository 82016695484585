// Abstracts
@import "abstracts/variables";
@import "abstracts/mixins";
// Vendors
@import "~bootstrap/scss/bootstrap";
@import '~@fortawesome/fontawesome-free/css/all.css';
@import "~@fortawesome/fontawesome-free/css/v4-shims.css";
@import "~noty/lib/noty.css";
@import "~noty/lib/themes/metroui.css";
// Base styles
@import "base/reset";
@import "base/utilities";
// Layouts
@import "layouts/sidebar";
@import "layouts/navbar";
// Components
// Pages
@import "pages/dashboard";
@import "../../app/scss/components/fancy-check";
//widgets
@import "widget/_";
