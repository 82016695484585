.color-dark-blue {
  color: $dark-blue_color;
}

.u-mt-n5 {
  margin-top: -5rem;
}

.u-mt-n6 {
  margin-top: -6rem;
}

.u-mr-n4 {
  margin-right: -4rem;
}

.u-mb-n7 {
  margin-bottom: -7rem;
}

.u-index-2 {
  z-index: 2;
}

.u-hidden-phone {
  display: block;
  @include responsive(phone-land) {
    display: none;
  }
}

.u-letter-spacing {
  letter-spacing: .1rem;
}

.playstation-logo{
  height: 5rem;
  margin: 1.5rem auto;
}